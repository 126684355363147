import { PageProps } from "gatsby"
import Layout from "@/components/Layout"
import SEO from "@/components/SEO"

const Error404Page: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default Error404Page
